/* eslint-disable react/display-name */

import Col from "react-bootstrap/Col"
import LocalizedLink from "components/localized-link"
import PropTypes from "prop-types"
import React from "react"
import Row from "react-bootstrap/Row"

const LayoutYonet = ({ activeIndex, children }) => {
  if (!activeIndex) activeIndex = 0
  return (
    <Row className="my-3 mx-0 m-md-5">
      <Col md="2">
        <div className="nav flex-column nav-pills" aria-orientation="vertical">
          <LocalizedLink
            to="/hesap/yonet/siparisler/"
            className={`nav-link ${activeIndex == 0 && "active"}`}
          >
            Siparişler
          </LocalizedLink>
          <LocalizedLink
            to="/hesap/yonet/geribildirimler/"
            className={`nav-link ${activeIndex == 1 && "active"}`}
          >
            Geribildirimler
          </LocalizedLink>
        </div>
      </Col>
      <Col>{children}</Col>
    </Row>
  )
}
LayoutYonet.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  children: PropTypes.node,
}
export default LayoutYonet
