import PropTypes from "prop-types"
import React from "react"
import { navigate } from "@reach/router"
import styles from "./index.module.scss"

export default function Form({ handleSubmit, handleUpdate }) {
  return (
    <form
      method="post"
      className="ml-auto mr-auto"
      style={{ width: 240 }}
      onSubmit={event => {
        handleSubmit(event)
        navigate(`/hesap/profil`)
      }}
    >
      <div className="form-group mt-3 mb-0">
        <label className="text-muted col-form-label-sm mb-0">E-Posta</label>
        <div className="form-row align-items-center">
          <div className="col-auto w-100">
            <input
              className="form-control mb-2"
              type="text"
              name="username"
              onChange={handleUpdate}
            />
          </div>
        </div>
      </div>

      <div className="form-group mb-0">
        <label className="text-muted col-form-label-sm mb-0">Şifre</label>
        <div className="form-row align-items-center">
          <div className="col-auto w-100">
            <input
              className="form-control mb-2"
              type="password"
              name="password"
              onChange={handleUpdate}
            />
          </div>
        </div>
      </div>
      <div className="form-group mt-3 mb-0">
        <div className="form-row align-items-center">
          <input
            className="ml-auto mr-2 btn btn-primary"
            type="submit"
            value="Giriş Yap"
          />
        </div>
      </div>
    </form>
  )
}

Form.propTypes = {
  handleSubmit: PropTypes.func,
  handleUpdate: PropTypes.func,
}
