/* eslint-disable react/display-name */

import React, { useEffect, useState } from "react"
import { getLisans, sendLisansMail } from "utils/api"

import PropTypes from "prop-types"
import Spinner from "react-bootstrap/Spinner"

const Lisans = ({ siparis, fileAddedHandler }) => {
  const [lisans, setLisans] = useState("")
  const [imza, setImza] = useState("")
  const [mailSonuc, setMailSonuc] = useState("")
  const [mailSending, setMailSending] = useState(false)

  useEffect(() => {
    getLisans(siparis)
      .then(resp => resp.text())
      .then(html => {
        const [text, imzaResult] = html.split("@@@")
        setImza(imzaResult)
        setLisans(text)
      })
  }, [])

  function onMailGonderClick() {
    setMailSending(true)
    sendLisansMail(siparis, imza)
      .then(resp => resp.text())
      .then(html => {
        setMailSonuc(html)
        setMailSending(false)
        fileAddedHandler(imza, `lisans/tkt-lisans-${imza}.lic`)
      })
  }
  return (
    <>
      <div
        className="bg-light py-2 px-4 m-2 rounded"
        dangerouslySetInnerHTML={{ __html: lisans }}
      />
      <button
        onClick={onMailGonderClick}
        id="gonder"
        className="btn btn-success btn-lg"
        disabled={mailSending}
      >
        Mail Olarak Gönder
      </button>
      {mailSending && (
        <h1 className="text-center">
          <Spinner animation="border" /> {imza} imzalı lisans mail
          gönderiliyor...
        </h1>
      )}
      {!mailSending && mailSonuc !== "" && (
        <div className="form-group mt-3 mb-0">
          <label className="text-muted col-form-label-sm mb-0">
            Mail Sonucu
          </label>
          <textarea
            readOnly
            rows={6}
            className="form-control"
            value={mailSonuc}
          ></textarea>
        </div>
      )}
    </>
  )
}
Lisans.propTypes = {
  siparis: PropTypes.object,
  fileAddedHandler: PropTypes.func,
}
export default Lisans
