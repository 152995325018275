/* eslint-disable react/display-name */

import Col from "react-bootstrap/Col"
import PropTypes from "prop-types"
import React from "react"
import Row from "react-bootstrap/Row"

//TODO: Fatura seri ve no girilmeden fatura gönderilmemeli
const GeribildirimDetay = ({ geribildirim, onChangeHandler }) => {
  return (
    <>
      <Row className="mt-2">
        <Col md="4">
          <div className="form-group mt-3 mb-0">
            <label className="text-muted col-form-label-sm mb-0">E-Posta</label>
            <div className="form-row align-items-center">
              <div className="col-auto w-100">
                <input
                  type="text"
                  value={geribildirim["EPosta"] || ""}
                  name="EPosta"
                  onChange={onChangeHandler}
                  className="form-control  form-control-sm mb-2"
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className="form-group mt-2+ mb-0">
        <label className="text-muted col-form-label-sm mb-0">Açıklama</label>
        <div className="form-row align-items-center">
          <div className="col-auto w-100">
            <textarea
              rows={4}
              disabled
              type="text"
              value={geribildirim["Aciklama"] || ""}
              name="Aciklama"
              onChange={onChangeHandler}
              className="form-control  form-control-sm mb-2"
            />
          </div>
        </div>
      </div>
      <div className="form-group mt-2+ mb-0">
        <label className="text-muted col-form-label-sm mb-0">Cevap</label>
        <div className="form-row align-items-center">
          <div className="col-auto w-100">
            <textarea
              rows={4}
              type="text"
              value={geribildirim["Cevap"] || ""}
              name="Cevap"
              onChange={onChangeHandler}
              className="form-control  form-control-sm mb-2"
            />
          </div>
        </div>
      </div>
    </>
  )
}
GeribildirimDetay.propTypes = {
  geribildirim: PropTypes.object,
  onChangeHandler: PropTypes.func,
}
export default GeribildirimDetay
