/* eslint-disable react/display-name */

import LocalizedLink from "components/localized-link"
import OrderStatusConverter from "./order-status-converter"
import React from "react"

const columns = [
  {
    name: "İşlem",
    cell: row => (
      <LocalizedLink
        to={`${row.post_id}`}
        role="button"
        className="btn btn-sm btn-info m-2"
      >
        Düzenle
      </LocalizedLink>
    ),
    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
  },
  {
    name: "İsim",
    selector: "_billing_first_name",
    cell: row => (
      <span>
        {row._billing_first_name} {row._billing_last_name}
      </span>
    ),
    sortable: true,
  },
  {
    name: "Program Kodu",
    selector: "program_kodu",
    sortable: true,
  },
  {
    name: "Mail Adresi",
    selector: "_billing_email",
    sortable: true,
  },
  {
    name: "Satın Alma Tarihi",
    selector: "post_date",
    sortable: true,
  },
  {
    name: "Durum",
    selector: "post_status",
    sortable: true,
    cell: row => <OrderStatusConverter row={row} />,
  },
  {
    name: "Lisans Türü",
    selector: "order_items",
    sortable: true,
  },
]

export default columns
