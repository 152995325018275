/* eslint-disable react/display-name */

import Col from "react-bootstrap/Col"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PropTypes from "prop-types"
import React from "react"
import Row from "react-bootstrap/Row"
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons"

const SiparisDetay = ({ siparis, dosyalar, onChangeHandler }) => {
  function showForm() {
    document.getElementById("faturalama-text").style.display = "none"
    document.getElementById("faturalama-form-icon").style.display = "none"
    document.getElementById("faturalama-form").style.display = "block"
  }
  return (
    <>
      {
        <>
          <Row className="mt-4">
            <Col md="3">
              <span
                className="d-block text-dark text-center font-weight-bold"
                style={{ fontSize: 18 }}
              >
                Genel
              </span>
              <div className="form-group mt-3 mb-0">
                <label className="text-muted col-form-label-sm mb-0">
                  Oluşturulan tarih
                </label>
                <div className="form-row align-items-center">
                  <div className="col-auto">
                    <input
                      type="text"
                      value={siparis["post_date"] || ""}
                      name="post_date"
                      onChange={onChangeHandler}
                      className="form-control  form-control-sm mb-2"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group  mb-0">
                <label className="text-muted col-form-label-sm mb-0">
                  Durum
                </label>
                <div className="form-row align-items-center ">
                  <div className="col-auto ">
                    <select
                      value={siparis["post_status"]}
                      name="post_status"
                      onChange={onChangeHandler}
                      className="form-control  form-control-sm"
                    >
                      <option value="wc-completed">Tamamlandı</option>
                      <option value="wc-cancelled">İptal Edildi</option>
                      <option value="wc-on-hold">Ödeme Bekleniyor</option>
                      <option value="wc-pending">Bekleniyor</option>
                      <option value="trash">Çöp</option>
                      <option value="wc-refunded">İade Edildi</option>
                      <option value="wc-processing">İşleniyor</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="form-group mt-2  mb-0 ">
                <label className="text-muted col-form-label-sm mb-0">
                  Ürün
                </label>
                <div className="form-row align-items-center ">
                  <div className="col-auto ">
                    <select
                      value={siparis["order_items"]}
                      name="order_items"
                      onChange={onChangeHandler}
                      className="form-control form-control-sm"
                    >
                      <option value="Başlangıç Paketi">Başlangıç Paketi</option>
                      <option value="Standart Paket">Standart Paket</option>
                      <option value="Standart Paket - Destek Hizmeti Yok">
                        Standart Paket - Desteksiz
                      </option>
                      <option value="Süper Paket">Süper Paket</option>
                      <option value="1 YIL Lisans">1 YIL Lisans</option>
                      <option value="Sınırsız Lisans">Sınırsız Lisans</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="form-group mt-2  mb-0">
                <div className="form-row align-items-center">
                  <div className="col-auto">
                    <label className="text-muted col-form-label-sm mb-0">
                      Fiyat
                    </label>
                    <input
                      type="text"
                      className="form-control  form-control-sm mb-2"
                      style={{ width: 80 }}
                      readOnly
                      value={siparis["_order_total"] - siparis["_order_tax"]}
                    />
                  </div>
                  <div className="col-auto">
                    <label className="text-muted col-form-label-sm mb-0">
                      KDV
                    </label>
                    <input
                      type="text"
                      className="form-control  form-control-sm mb-2"
                      value={siparis["_order_tax"] || ""}
                      name="_order_tax"
                      style={{ width: 80 }}
                      onChange={onChangeHandler}
                    />
                  </div>

                  <div className="col-auto ">
                    <label className="text-muted col-form-label-sm mb-0">
                      Toplam
                    </label>
                    <input
                      type="text"
                      className="form-control  form-control-sm mb-2"
                      value={siparis["_order_total"] || ""}
                      name="_order_total"
                      onChange={onChangeHandler}
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col md="4">
              <span
                className="text-center text-dark font-weight-bold d-block mt-4 mt-md-0"
                style={{ fontSize: 18 }}
              >
                <span>Faturalama</span>{" "}
                <div
                  onClick={showForm}
                  id="faturalama-form-icon"
                  className="text-primary"
                  style={{ cursor: "pointer", display: "inline" }}
                >
                  <FontAwesomeIcon size="sm" icon={faPencilAlt} />
                </div>{" "}
              </span>

              <div className="text-muted  mt-3">
                <div id="faturalama-form" style={{ display: "none" }}>
                  <div className="form-group mt-3 mb-0">
                    <div className="form-row align-items-center">
                      <div className="col-auto">
                        <label className="text-muted col-form-label-sm mb-0">
                          Ad
                        </label>
                        <input
                          type="text"
                          value={siparis["_billing_first_name"] || ""}
                          name="_billing_first_name"
                          onChange={onChangeHandler}
                          className="form-control  form-control-sm"
                        />
                      </div>
                      <div className="col-auto">
                        <label className="text-muted col-form-label-sm mb-0">
                          Soyad
                        </label>
                        <input
                          type="text"
                          value={siparis["_billing_last_name"] || ""}
                          name="_billing_last_name"
                          onChange={onChangeHandler}
                          className="form-control  form-control-sm"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group mt-1  mb-0">
                    <label className="text-muted col-form-label-sm mb-0">
                      Firma
                    </label>
                    <div className="form-row align-items-center">
                      <div className="col-auto w-100 pr-4">
                        <input
                          type="text"
                          value={siparis["_billing_company"] || ""}
                          name="_billing_company"
                          onChange={onChangeHandler}
                          className="form-control  form-control-sm"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group mt-1 mb-0">
                    <div className="form-row align-items-center">
                      <div className="col-auto">
                        <label className="text-muted col-form-label-sm mb-0">
                          Adres satırı 1
                        </label>
                        <input
                          type="text"
                          value={siparis["_billing_address_1"] || ""}
                          name="_billing_address_1"
                          onChange={onChangeHandler}
                          className="form-control  form-control-sm"
                        />
                      </div>
                      <div className="col-auto">
                        <label className="text-muted col-form-label-sm mb-0">
                          Adres satırı 2
                        </label>
                        <input
                          type="text"
                          value={siparis["_billing_address_2"] || ""}
                          name="_billing_address_2"
                          onChange={onChangeHandler}
                          className="form-control  form-control-sm"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group mt-1  mb-0">
                    <div className="form-row align-items-center">
                      <div className="col-auto">
                        <label className="text-muted col-form-label-sm mb-0">
                          İl
                        </label>
                        <input
                          type="text"
                          value={siparis["_billing_state"] || ""}
                          name="_billing_state"
                          onChange={onChangeHandler}
                          className="form-control  form-control-sm"
                        />
                      </div>
                      <div className="col-auto">
                        <label className="text-muted col-form-label-sm mb-0">
                          İlçe
                        </label>
                        <input
                          type="text"
                          value={siparis["_billing_city"] || ""}
                          name="_billing_city"
                          onChange={onChangeHandler}
                          className="form-control  form-control-sm"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group mt-1 mb-0">
                    <div className="form-row align-items-center">
                      <div className="col-auto">
                        <label className="text-muted col-form-label-sm mb-0">
                          Ülke
                        </label>
                        <input
                          type="text"
                          value={siparis["_billing_country"] || ""}
                          name="_billing_country"
                          onChange={onChangeHandler}
                          className="form-control  form-control-sm"
                        />
                      </div>
                      <div className="col-auto">
                        <label className="text-muted col-form-label-sm mb-0">
                          Posta Kodu
                        </label>
                        <input
                          type="text"
                          value={siparis["_billing_postcode"] || ""}
                          name="_billing_postcode"
                          onChange={onChangeHandler}
                          className="form-control  form-control-sm"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group mt-1 mb-0">
                    <div className="form-row align-items-center">
                      <div className="col-auto">
                        <label className="text-muted col-form-label-sm mb-0">
                          E-Posta
                        </label>
                        <input
                          type="text"
                          value={siparis["_billing_email"] || ""}
                          name="_billing_email"
                          onChange={onChangeHandler}
                          className="form-control  form-control-sm"
                        />
                      </div>
                      <div className="col-auto">
                        <label className="text-muted col-form-label-sm mb-0">
                          Telefon
                        </label>
                        <input
                          type="text"
                          value={siparis["_billing_phone"] || ""}
                          name="_billing_phone"
                          onChange={onChangeHandler}
                          className="form-control  form-control-sm"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group mt-1 mb-0">
                    <div className="form-row align-items-center">
                      <div className="col-auto">
                        <label className="text-muted col-form-label-sm mb-0">
                          Vergi Dairesi
                        </label>
                        <input
                          type="text"
                          value={siparis["vergi_dairesi"] || ""}
                          name="vergi_dairesi"
                          onChange={onChangeHandler}
                          className="form-control  form-control-sm"
                        />
                      </div>
                      <div className="col-auto">
                        <label className="text-muted col-form-label-sm mb-0">
                          Vergi Numarası
                        </label>
                        <input
                          type="text"
                          value={siparis["vergi_no"] || ""}
                          name="vergi_no"
                          onChange={onChangeHandler}
                          className="form-control  form-control-sm"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group mt-1 mb-0">
                    <div className="form-row align-items-center">
                      <div className="col-auto">
                        <label className="text-muted col-form-label-sm mb-0">
                          Program Kodu
                        </label>
                        <input
                          type="text"
                          value={siparis["program_kodu"] || ""}
                          name="program_kodu"
                          onChange={onChangeHandler}
                          className="form-control  form-control-sm"
                        />
                      </div>
                      <div className="col-auto">
                        <label className="text-muted col-form-label-sm mb-0">
                          Ödeme Yöntemi
                        </label>
                        <select
                          id="odeme-yontemi"
                          value={siparis["_payment_method_title"]}
                          name="_payment_method_title"
                          onChange={onChangeHandler}
                          className="form-control  form-control-sm"
                        >
                          <option value="Online Ödeme">Online Ödeme</option>
                          <option value="Banka Havalesi">Banka Havalesi</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="faturalama-text" style={{ display: "block" }}>
                  <div>
                    {siparis["_billing_first_name"]}{" "}
                    {siparis["_billing_last_name"]}
                  </div>
                  <div>{siparis["_billing_company"]}</div>
                  <div>
                    {siparis["_billing_address_1"]}{" "}
                    {siparis["_billing_address_2"]}
                  </div>
                  <div>
                    {siparis["_billing_city"]} {siparis["_billing_state"]}{" "}
                    {siparis["_billing_country"]}
                  </div>
                  <div className="mt-3">
                    <span className="font-weight-bold text-gray d-block">
                      E-posta adresi:
                    </span>
                    <a href={`mailto:${siparis["_billing_email"]}`}>
                      {siparis["_billing_email"]}
                    </a>
                  </div>
                  <div className="mt-3">
                    <span className="font-weight-bold text-gray d-block">
                      Telefon:
                    </span>
                    <a href={`tel:${siparis["_billing_phone"]}`}>
                      {siparis["_billing_phone"]}
                    </a>
                  </div>

                  <div className="mt-3">
                    <span className="font-weight-bold text-gray d-block">
                      Program Kodu:
                    </span>
                    {siparis["program_kodu"]}
                  </div>

                  <div className="mt-3">
                    <span className="font-weight-bold text-gray d-block">
                      Vergi Dairesi ve Numarası:
                    </span>
                    {siparis["vergi_dairesi"]} {siparis["vergi_no"]}
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <span
                className="text-dark text-center font-weight-bold d-block mt-4 mt-md-0"
                style={{ fontSize: 18 }}
              >
                Detaylar{" "}
              </span>
              <div className="form-group mt-3 mb-0">
                <div className="form-row align-items-center">
                  <div className="col-auto">
                    <label className="text-muted col-form-label-sm mb-0">
                      Fatura Seri
                    </label>
                    <input
                      type="text"
                      placeholder="örn. A"
                      value={siparis["x-fatura_seri"] || ""}
                      name="x-fatura_seri"
                      onChange={onChangeHandler}
                      style={{ width: 70 }}
                      className="form-control  form-control-sm"
                    />
                  </div>
                  <div className="col-auto">
                    <label className="text-muted col-form-label-sm mb-0">
                      Fatura No
                    </label>
                    <input
                      type="text"
                      value={siparis["x-fatura_no"] || ""}
                      name="x-fatura_no"
                      onChange={onChangeHandler}
                      placeholder="örn. 1234567"
                      className="form-control  form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group mt-3 mb-0">
                <label className="text-muted col-form-label-sm mb-0">
                  Modüller:
                </label>
                <div className="form-row align-items-center ">
                  <div className="col-auto w-100 ">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="x-moduller"
                        onChange={onChangeHandler}
                        id="modul1"
                        value="BarkodOkumaYazdirma"
                        checked={
                          siparis["x-moduller"] &&
                          siparis["x-moduller"].indexOf("BarkodOkumaYazdirma") >
                            -1
                        }
                      />
                      <label className="form-check-label" htmlFor="modul1">
                        Barkod Okuma ve Yazdırma
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="x-moduller"
                        onChange={onChangeHandler}
                        id="modul2"
                        value="TopluVeriAktarimi"
                        checked={
                          siparis["x-moduller"] &&
                          siparis["x-moduller"].indexOf("TopluVeriAktarimi") >
                            -1
                        }
                      />
                      <label className="form-check-label" htmlFor="modul2">
                        Toplu Veri Aktarımı
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="x-moduller"
                        onChange={onChangeHandler}
                        id="modul3"
                        value="DetayliRaporlama"
                        checked={
                          siparis["x-moduller"] &&
                          siparis["x-moduller"].indexOf("DetayliRaporlama") > -1
                        }
                      />
                      <label className="form-check-label" htmlFor="modul3">
                        Detaylı Raporlama
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="x-moduller"
                        onChange={onChangeHandler}
                        id="modul4"
                        value="CokluKullanici"
                        checked={
                          siparis["x-moduller"] &&
                          siparis["x-moduller"].indexOf("CokluKullanici") > -1
                        }
                      />
                      <label className="form-check-label" htmlFor="modul4">
                        Çoklu Kullanıcı
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="x-moduller"
                        onChange={onChangeHandler}
                        id="modul5"
                        value="Ozellestirme"
                        checked={
                          siparis["x-moduller"] &&
                          siparis["x-moduller"].indexOf("Ozellestirme") > -1
                        }
                      />
                      <label className="form-check-label" htmlFor="modul5">
                        Özelleştirme
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="x-moduller"
                        onChange={onChangeHandler}
                        id="modul6"
                        value="KitapPaylasimEkrani"
                        checked={
                          siparis["x-moduller"] &&
                          siparis["x-moduller"].indexOf("KitapPaylasimEkrani") >
                            -1
                        }
                      />
                      <label className="form-check-label" htmlFor="modul6">
                        Kitap Paylaşım Ekranı
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group mt-3 mb-0">
                <label className="text-muted col-form-label-sm mb-0">
                  Gönderilen Dosyalar:
                </label>
                <ul className="list-group ml-0">
                  {dosyalar.map(dosya => (
                    <li
                      key={dosya.imza}
                      className="list-group-item d-flex justify-content-between align-items-center"
                    >
                      <span>
                        <a
                          href={`https://www.kodvizit.com/belgeindir.php?imza=${dosya.imza}`}
                        >
                          {dosya.dosya}
                        </a>
                      </span>
                      <span>
                        <i>{new Date(dosya.tarih).toLocaleString()}</i>
                      </span>
                      {dosya.dosya.endsWith("pdf") ||
                        (dosya.dosya.endsWith("zip") && (
                          <span className="badge badge-dark badge-pill">
                            Fatura
                          </span>
                        ))}
                      {dosya.dosya.endsWith("lic") && (
                        <span className="badge badge-primary badge-pill">
                          Lisans
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <hr />
              <h5>Ekstra</h5>
              <div className="form-group mt-3 mb-0">
                <label className="text-muted col-form-label-sm mb-0">
                  Tarayıcı Detayları:
                </label>
                <small className="d-block">
                  {siparis["_customer_user_agent"]}
                </small>
              </div>

              {siparis["_payment_method_title"] == "Online Ödeme" &&
                siparis["get_auth"] &&
                siparis["get_auth"] !== "" && (
                  <div className="form-group mt-3 mb-0">
                    <label className="text-muted col-form-label-sm mb-0">
                      get_auth:
                    </label>
                    <textarea
                      readOnly
                      rows={6}
                      className="form-control"
                      value={JSON.stringify(
                        JSON.parse(siparis["get_auth"]),
                        null,
                        2
                      )}
                    ></textarea>
                  </div>
                )}
            </Col>
          </Row>
        </>
      }
    </>
  )
}
SiparisDetay.propTypes = {
  siparis: PropTypes.object,
  dosyalar: PropTypes.arrayOf(PropTypes.object),
  onChangeHandler: PropTypes.func,
}
export default SiparisDetay

export function orderItemChanged(orderItem) {
  let selectValues = []

  if (orderItem === "Başlangıç Paketi")
    selectValues = [
      "BarkodOkumaYazdirma",
      "TopluVeriAktarimi",
      "DetayliRaporlama",
    ]
  else if (orderItem.startsWith("Standart Paket"))
    selectValues = [
      "BarkodOkumaYazdirma",
      "TopluVeriAktarimi",
      "DetayliRaporlama",
      "CokluKullanici",
    ]
  else if (orderItem === "Süper Paket")
    selectValues = [
      "BarkodOkumaYazdirma",
      "TopluVeriAktarimi",
      "DetayliRaporlama",
      "CokluKullanici",
      "Ozellestirme",
      "KitapPaylasimEkrani",
    ]

  return selectValues
}
