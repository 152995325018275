import { getCurrentUser, isLoggedIn, logout } from "utils/auth"

import Container from "react-bootstrap/Container"
import React from "react"
import { navigate } from "gatsby"

const Profile = () => {
  const { name } = getCurrentUser()

  return (
    <Container className="my-5">
      <p>Welcome back to your profile, {name}!</p>
      <p>
        This is a client-only route. You could set up a form to save information
        about a user here.
      </p>
      {isLoggedIn() ? (
        <a
          href="/"
          onClick={event => {
            event.preventDefault()
            logout(() => navigate(`/hesap/giris`))
          }}
        >
          Logout
        </a>
      ) : null}
    </Container>
  )
}

export default Profile
