/* eslint-disable react/display-name */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import LocalizedLink from "components/localized-link"
import React from "react"
import { faKey } from "@fortawesome/free-solid-svg-icons"

const columns = [
  {
    name: "İşlem",
    cell: row => (
      <LocalizedLink
        to={`${row.Id}`}
        role="button"
        className="btn btn-sm btn-info m-2"
      >
        Görüntüle
      </LocalizedLink>
    ),
    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
  },
  {
    name: "Program Kodu",
    selector: "ProgramKod",
    sortable: true,
  },
  {
    name: "Mail Adresi",
    selector: "EPosta",
    sortable: true,
  },
  {
    name: "Sürüm",
    selector: "Surum",
    sortable: true,
  },
  {
    name: "Şifre",
    selector: "Sifre",
    sortable: true,
  },
  {
    name: "Cevaplandı",
    cell: row => (
      <span
        className={`badge badge-pill ${
          row.Cevap ? "badge-success" : "badge-danger"
        }`}
      >
        {row.Cevap ? "EVET" : "HAYIR"}
      </span>
    ),
    sortable: true,
  },
  {
    name: "Şifre İsteği",
    cell: row => (
      <FontAwesomeIcon
        size="sm"
        className={`${row["Aciklama"] !== "Şifremi Unuttum" && "d-none"}`}
        icon={faKey}
      />
    ),
    sortable: true,
  },
]

export default columns
