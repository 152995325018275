import PropTypes from "prop-types"
import React from "react"
import { isLoggedIn } from "utils/auth"
import { navigate } from "gatsby"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!isLoggedIn() && location.pathname !== `/hesap/giris`) {
    // If we’re not logged in, redirect to the home page.
    navigate(`/hesap/giris`)
    return null
  }

  return <Component {...rest} />
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  location: PropTypes.object,
}

export default PrivateRoute
