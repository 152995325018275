/* eslint-disable react/display-name */

import {
  faAsterisk,
  faCameraRetro,
  faDatabase,
  faFileAlt,
  faLaptop,
  faSave,
  faTrash,
} from "@fortawesome/free-solid-svg-icons"
import {
  getGeribildirimById,
  getGeribildirimDosyalar,
  removeGeribildirim,
  sendGeribildirimPassword,
  updateGeribildirim,
} from "utils/api"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import GeribildirimDetay from "./geribildirim-detay"
import LayoutYonet from "components/layout-yonet"
import PropTypes from "prop-types"
import React from "react"
import Spinner from "react-bootstrap/Spinner"

const Geribildirim = ({ id }) => {
  const [geribildirim, setGeribildirim] = React.useState({})
  const [dosyalar, setDosyalar] = React.useState([])

  React.useEffect(() => {
    getGeribildirimById(id)
      .then(response => response.json())
      .then(json => {
        if (!json || !json[0]) return
        const obj = json[0]
        for (var propName in obj) {
          if (obj[propName] === null || obj[propName] === undefined) {
            obj[propName] = ""
          }
        }
        setGeribildirim(obj)
        getGeribildirimDosyalar(obj["DosyaAdi"])
          .then(response => response.json())
          .then(json => setDosyalar(json))
      })
  }, [])

  function onChangeHandler(e) {
    let newValue = e.target.value
    const name = e.target.name
    const newGeribildirim = { ...geribildirim }

    newGeribildirim[name] = newValue
    setGeribildirim(newGeribildirim)
  }

  const renderComponent =
    Object.keys(geribildirim).length === 0 ? (
      <h1 className="text-center">
        <Spinner animation="border" /> {id} numaralı geribildirim yükleniyor...
      </h1>
    ) : (
      <>
        <div className="d-flex flex-row">
          <div>
            <h3>#{id} numaralı geribildirim</h3>
            <div className="text-muted">
              Sürüm: {geribildirim["Surum"]}
              <br />
              Program kodu: {geribildirim["ProgramKod"]}
              <br /> Şifre: {geribildirim["Sifre"]}
            </div>
          </div>
          <div className="ml-auto d-flex flex-column">
            <div>
              <button
                className="btn btn-danger m-2"
                onClick={() => {
                  if (
                    confirm("Geribildirimi silmek istediğinize emin misiniz?")
                  ) {
                    removeGeribildirim(geribildirim.Id).then(() => {
                      alert("Geribildirim silindi")
                    })
                  }
                }}
              >
                <FontAwesomeIcon icon={faTrash} className="mr-2" />
                Sil
              </button>
              <button
                className="btn btn-success m-2"
                onClick={() => {
                  if (confirm("Şifreyi göndermek istediğinize emin misiniz?")) {
                    sendGeribildirimPassword(geribildirim).then(() => {
                      alert("Şifre gönderildi")
                    })
                  }
                }}
              >
                <FontAwesomeIcon icon={faAsterisk} className="mr-2" />
                Şifreyi Gönder
              </button>
              <button
                className="btn btn-info m-2"
                onClick={() => {
                  if (
                    confirm(
                      "Geribildirimi güncellemek istediğinize emin misiniz?"
                    )
                  ) {
                    updateGeribildirim(geribildirim).then(() => {
                      alert("Geribildirim güncellendi")
                    })
                  }
                }}
              >
                <FontAwesomeIcon icon={faSave} className="mr-2" />
                Güncelle
              </button>
            </div>
            <div className="d-flex justify-content-end">
              <a
                title="Ekran görüntüsünü aç"
                href={`https://www.kodvizit.com/tkt_config/upload/${geribildirim["DosyaAdi"]}.jpg`}
                role="button"
                target="_blank"
                rel="noreferrer"
                className={`badge badge-light m-1 ${
                  !dosyalar.some(x => x.endsWith("jpg")) && "d-none"
                }`}
              >
                <FontAwesomeIcon icon={faCameraRetro} className="mr-2" /> jpg
              </a>
              <a
                title="Log dosyasını aç"
                href={`https://www.kodvizit.com/tkt_config/upload/${geribildirim["DosyaAdi"]}.log`}
                role="button"
                target="_blank"
                rel="noreferrer"
                className={`badge badge-light m-1 ${
                  !dosyalar.some(x => x.endsWith("log")) && "d-none"
                }`}
              >
                <FontAwesomeIcon icon={faFileAlt} className="mr-2" /> log
              </a>
              <a
                title="Sistem bilgilerini aç"
                href={`https://www.kodvizit.com/tkt_config/upload/${geribildirim["DosyaAdi"]}.txt`}
                role="button"
                target="_blank"
                rel="noreferrer"
                className={`badge badge-light m-1 ${
                  !dosyalar.some(x => x.endsWith("txt")) && "d-none"
                }`}
              >
                <FontAwesomeIcon icon={faLaptop} className="mr-2" /> sistem
              </a>
              <a
                title="Veritabanını indir"
                href={`https://www.kodvizit.com/tkt_config/upload/${geribildirim["DosyaAdi"]}.db`}
                role="button"
                target="_blank"
                rel="noreferrer"
                className={`badge badge-light m-1 ${
                  !dosyalar.some(x => x.endsWith("db")) && "d-none"
                }`}
              >
                <FontAwesomeIcon icon={faDatabase} className="mr-2" /> db
              </a>
            </div>
          </div>
        </div>
        <GeribildirimDetay
          geribildirim={geribildirim}
          onChangeHandler={onChangeHandler}
          dosyalar={dosyalar}
        />
      </>
    )

  return <LayoutYonet activeIndex={1}>{renderComponent}</LayoutYonet>
}
Geribildirim.propTypes = {
  id: PropTypes.string,
}
export default Geribildirim
