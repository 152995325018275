import { getCurrentUser, handleLogin, isLoggedIn } from "utils/auth"

import Container from "react-bootstrap/Container"
import Form from "components/form"
import React from "react"
import { navigate } from "gatsby"

class Login extends React.Component {
  state = {
    username: ``,
    password: ``,
  }

  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    handleLogin(this.state, this.redirectAfterLogin)
  }

  redirectAfterLogin() {
    if (isLoggedIn()) {
      if (getCurrentUser().role == 1) navigate(`/hesap/yonet/siparisler`)
      else navigate(`/hesap/profil`)
    }
  }
  render() {
    this.redirectAfterLogin()
    return (
      <Form
        handleUpdate={e => this.handleUpdate(e)}
        handleSubmit={e => this.handleSubmit(e)}
      />
    )
  }
}

export default Login
