/* eslint-disable react/display-name */

import React, { useEffect, useState } from "react"
import { getFatura, sendFaturaMail, uploadFaturaFile } from "utils/api"

import PropTypes from "prop-types"
import Spinner from "react-bootstrap/Spinner"
import { Tab, Tabs } from "react-bootstrap"

const Fatura = ({ siparis, fileAddedHandler }) => {
  const [fatura, setFatura] = useState("")
  const [sunucuyaYuklendi, setSunucuyaYuklendi] = useState(false)
  const [sunucuyaYukleniyor, setSunucuyaYukleniyor] = useState(false)
  const [imza, setImza] = useState("")
  const [mailSonuc, setMailSonuc] = useState("")
  const [mailSending, setMailSending] = useState(false)
  const [tab, setTab] = useState("fatura")
  const [file, setFile] = useState(null)

  useEffect(() => {
    getFatura(siparis)
      .then(resp => resp.text())
      .then(html => {
        const [text, imzaResult] = html.split("@@@")
        setImza(imzaResult)
        setFatura(text)
      })
  }, [])
  function onMailGonderClick() {
    let dosyaAdi = `pdf/tkt-fatura-${imza}.pdf`
    if (tab === "efatura") {
      dosyaAdi = `zip/tkt-fatura-${imza}.zip`
    }
    setMailSending(true)
    sendFaturaMail(siparis, imza, dosyaAdi)
      .then(resp => resp.text())
      .then(html => {
        setMailSonuc(html)
        setMailSending(false)
        fileAddedHandler(imza, dosyaAdi)
      })
  }
  function onUploadClick() {
    setSunucuyaYuklendi(false)
    setSunucuyaYukleniyor(true)
    console.log(file)
    uploadFaturaFile(file)
      .then(resp => resp.text())
      .then(imza => {
        setImza(imza)
        setSunucuyaYuklendi(true)
        setSunucuyaYukleniyor(false)
      })
  }
  return (
    <>
      <Tabs activeKey={tab} defaultActiveKey="fatura" onSelect={k => setTab(k)}>
        <Tab eventKey="fatura" title="Fatura">
          <div
            className="bg-light py-2 px-4 m-2 rounded"
            dangerouslySetInnerHTML={{ __html: fatura }}
          />
        </Tab>
        <Tab eventKey="efatura" title="E-Fatura">
          <div className="my-4">
            Zip dosyasını seç:
            <input
              type="file"
              className="ml-2"
              name="filename"
              onChange={e => {
                setFile(e.target.files[0])
              }}
            />
            <button
              className="btn btn-primary btn-sm d-block mt-2"
              onClick={onUploadClick}
              disabled={sunucuyaYukleniyor}
            >
              {!sunucuyaYukleniyor ? (
                <span>Dosyayı Sunucuya Yükle</span>
              ) : (
                <span>
                  <Spinner animation="border" size="sm" /> Dosya Yükleniyor...
                </span>
              )}
            </button>
          </div>
        </Tab>
      </Tabs>

      <button
        onClick={onMailGonderClick}
        id="gonder"
        className="btn btn-success btn-lg"
        disabled={mailSending || (tab === "efatura" && !sunucuyaYuklendi)}
      >
        Mail Olarak Gönder
      </button>
      {mailSending && (
        <h1 className="text-center">
          <Spinner animation="border" /> {imza} imzalı fatura mail ile
          gönderiliyor...
        </h1>
      )}
      {!mailSending && mailSonuc !== "" && (
        <div className="form-group mt-3 mb-0">
          <label className="text-muted col-form-label-sm mb-0">
            Mail Sonucu
          </label>
          <textarea
            readOnly
            rows={6}
            className="form-control"
            value={mailSonuc}
          ></textarea>
        </div>
      )}
    </>
  )
}
Fatura.propTypes = {
  siparis: PropTypes.object,
  fileAddedHandler: PropTypes.func,
}
export default Fatura
