import DataTable from "components/data-table"
import LayoutYonet from "components/layout-yonet"
import React from "react"
import columns from "./columns"
import { getGeribildirim } from "utils/api"

const Geribildirimler = () => {
  const [geribildirimler, setGeribildirimler] = React.useState([])

  React.useEffect(() => {
    getGeribildirim()
      .then(response => response.json())
      .then(json => setGeribildirimler(json))
  }, [])

  return (
    <LayoutYonet activeIndex={1}>
      {geribildirimler.length <= 0 && (
        <h1 className="text-center">Geribildirimler yükleniyor...</h1>
      )}
      {geribildirimler.length > 0 && (
        <DataTable
          title="Geribildirimler"
          columns={columns}
          data={geribildirimler}
        />
      )}
    </LayoutYonet>
  )
}

export default Geribildirimler
