import DataTable from "components/data-table"
import LayoutYonet from "components/layout-yonet"
import React from "react"
import columns from "./columns"
import { getSiparis } from "utils/api"

const Siparisler = () => {
  const [siparisler, setSiparisler] = React.useState([])

  React.useEffect(() => {
    getSiparis()
      .then(response => response.json())
      .then(json => setSiparisler(json))
  }, [])

  return (
    <LayoutYonet activeIndex={0}>
      {siparisler.length <= 0 && (
        <h1 className="text-center">Siparişler yükleniyor...</h1>
      )}
      {siparisler.length > 0 && (
        <DataTable title="Siparişler" columns={columns} data={siparisler} />
      )}
    </LayoutYonet>
  )
}

export default Siparisler
