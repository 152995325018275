import Layout from "components/layout"
import PropTypes from "prop-types"
import React from "react"
import SEO from "components/seo"
import svgNotFound from "images/404.svg"
import useTranslations from "components/use-translations"

export default function NotFound({ noLayout }) {
  const {
    e404: { seoTitle, seoDescription, aradiginizSayfayiBulamadik },
  } = useTranslations()
  const elem = (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        pathname="https://www.kodvizit.com/"
        robots="noindex,follow"
        breadcrumbList={["404"]}
      />

      <div className="my-5 text-center">
        <div>
          <img src={svgNotFound} height="200" alt="404 error image" />
          <h1 className="my-auto font-weight-bold text-danger">404</h1>
          <h1 className="my-auto font-weight-bold">
            {aradiginizSayfayiBulamadik}
          </h1>
        </div>
      </div>
    </Layout>
  )

  return (
    <>
      {!noLayout && <>{elem}</>}
      {noLayout && <>{elem}</>}
    </>
  )
}
NotFound.propTypes = {
  noLayout: PropTypes.bool,
}
