/* eslint-disable react/display-name */

import SiparisDetay, { orderItemChanged } from "./siparis-detay"
import {
  faCertificate,
  faFileInvoice,
  faLessThan,
  faLock,
  faSave,
} from "@fortawesome/free-solid-svg-icons"
import { getDosyalar, getSiparisById, updateOrder } from "utils/api"
import citiesByCode from "turkey-neighbourhoods/data/extra/cities_by_code.json"

import Fatura from "./fatura"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import LayoutYonet from "components/layout-yonet"
import Lisans from "./lisans"
import PropTypes from "prop-types"
import React from "react"
import Spinner from "react-bootstrap/Spinner"

const Siparis = ({ id }) => {
  const [siparis, setSiparis] = React.useState({})
  const [originalSiparis, setOriginalSiparis] = React.useState({})
  const [dosyalar, setDosyalar] = React.useState([])
  const [selectedPageIndex, setSelectedPageIndex] = React.useState(0)

  React.useEffect(() => {
    getSiparisById(id)
      .then(response => response.json())
      .then(json => {
        if (!json || !json[0]) return
        const obj = json[0]
        for (var propName in obj) {
          if (obj[propName] === null || obj[propName] === undefined) {
            obj[propName] = ""
          }
        }
        setOriginalSiparis({ ...obj })
        obj["x-moduller"] = orderItemChanged(obj["order_items"])
        obj["post_date"] = new Date(
          obj["post_date"].replace(/-/g, "/")
        ).toLocaleDateString()
        if (
          obj["_billing_state"] &&
          obj["_billing_state"].length === 4 &&
          obj["_billing_state"].startsWith("TR")
        ) {
          const plate = obj["_billing_state"].substring(2)
          obj["_billing_state"] = citiesByCode.find(pair => pair[0] == plate)[1]
        }
        setSiparis({ ...obj })
      })
    getDosyalar(id)
      .then(response => response.json())
      .then(json => {
        setDosyalar(json)
      })
  }, [])

  function onChangeHandler(e) {
    let newValue = e.target.value
    const name = e.target.name
    const newSiparis = { ...siparis }
    if (name === "x-moduller") {
      newValue = Array.from(document.getElementsByName(name))
        .filter(el => el.checked)
        .map(el => el.value)
    }

    if (name === "order_items") {
      newSiparis["x-moduller"] = orderItemChanged(newValue)
    }

    newSiparis[name] = newValue
    setSiparis(newSiparis)
  }
  function onFileAdded(signature, fileName) {
    const files = [...dosyalar]
    files.push({
      imza: signature,
      dosya: fileName,
      tarih: new Date().toJSON().slice(0, 19).replace("T", " "),
    })
    setDosyalar(files)
  }

  function isFileShouldSend(fileType) {
    if (!siparis) return false
    return (
      (siparis["post_status"].endsWith("completed") ||
        siparis["post_status"].endsWith("proccessing")) &&
      !dosyalar.some(x =>
        x.dosya.endsWith(fileType == "fatura" ? "pdf" || "zip" : "lic")
      )
    )
  }

  const renderComponent =
    Object.keys(siparis).length === 0 ? (
      <h1 className="text-center">
        <Spinner animation="border" /> {id} numaralı sipariş yükleniyor...
      </h1>
    ) : (
      <>
        <div className="d-flex flex-row">
          <div>
            <h3>#{id} numaralı sipariş</h3>
            <div className="text-muted">
              {siparis["_payment_method_title"]} ile ödeme. Müşteri IP:{" "}
              {siparis["_customer_ip_address"]}
            </div>
          </div>
          <div className="ml-auto">
            <button
              className="btn btn-primary m-2"
              onClick={() => {
                if (siparis["x-fatura_seri"] && siparis["x-fatura_no"])
                  setSelectedPageIndex(2)
                else
                  alert(
                    "Fatura seri veya fatura no girilmeden fatura oluşturulamaz"
                  )
              }}
            >
              <FontAwesomeIcon icon={faFileInvoice} className="mr-2" />
              Fatura Gönder
            </button>

            <button
              className="btn btn-success m-2"
              onClick={() => setSelectedPageIndex(1)}
            >
              <FontAwesomeIcon icon={faCertificate} className="mr-2" />
              Lisans Gönder
            </button>
            <button
              className="btn btn-info m-2"
              onClick={() => {
                if (
                  confirm("Değişiklikleri kaydetmek istediğinize emin misiniz?")
                ) {
                  updateOrder({
                    ...siparis,
                    ["post_date"]: originalSiparis["post_date"],
                    ["_billing_state"]: originalSiparis["_billing_state"],
                  })
                    .then(response => response.text())
                    .then(text => {
                      alert(
                        text.includes("UPDATED")
                          ? `Sipariş başarılı bir şekilde güncellendi.`
                          : `Güncelleme sırasında hata oluştu:\n${text}`
                      )
                    })
                }
              }}
            >
              <FontAwesomeIcon icon={faSave} className="mr-2" />
              Kaydet
            </button>

            <button
              className="btn btn-danger m-2"
              // TODO: Deaktif etme işin  i yap
              onClick={() => alert("Henüz işe yaramıyor")}
            >
              <FontAwesomeIcon icon={faLock} className="mr-2" />
              Deaktif Yap
            </button>
          </div>
        </div>
        <div className="mt-2">
          {isFileShouldSend("fatura") && (
            <span className="m-1 badge badge-danger">Fatura Gönderilmeli</span>
          )}
          {isFileShouldSend("lisans") && (
            <span className="m-1 badge badge-warning ">
              Lisans Gönderilmeli
            </span>
          )}
        </div>
        {selectedPageIndex !== 0 && (
          <button
            className="btn btn-sm btn-outline-primary m-2 mb-5"
            onClick={() => setSelectedPageIndex(0)}
          >
            <FontAwesomeIcon icon={faLessThan} className="mr-2" />
            Detaylara Dön
          </button>
        )}
        {selectedPageIndex == 0 && (
          <SiparisDetay
            dosyalar={dosyalar}
            siparis={siparis}
            onChangeHandler={onChangeHandler}
          />
        )}{" "}
        {selectedPageIndex == 1 && (
          <Lisans siparis={siparis} fileAddedHandler={onFileAdded} />
        )}
        {selectedPageIndex == 2 && (
          <Fatura siparis={siparis} fileAddedHandler={onFileAdded} />
        )}
      </>
    )

  return <LayoutYonet activeIndex={0}>{renderComponent}</LayoutYonet>
}
Siparis.propTypes = {
  id: PropTypes.string,
}
export default Siparis
