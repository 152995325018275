import ClientOnly from "components/client-only"
import Geribildirim from "components/geribildirim"
import Geribildirimler from "components/geribildirimler"
import Layout from "components/layout"
import Login from "components/login"
import NotFound from "./404"
import PrivateRoute from "components/private-route"
import Profile from "components/profile"
import React from "react"
import { Router } from "@reach/router"
import Siparis from "components/siparis"
import Siparisler from "components/siparisler"

const Hesap = () => (
  <ClientOnly>
    <Layout>
      <Router basepath="/hesap">
        <PrivateRoute path="/profil" component={Profile} />
        <PrivateRoute path="/yonet/siparisler/:id" component={Siparis} />
        <PrivateRoute path="/yonet/siparisler" component={Siparisler} />
        <PrivateRoute path="/yonet/" component={Siparisler} />
        <PrivateRoute
          path="/yonet/geribildirimler/:id"
          component={Geribildirim}
        />
        <PrivateRoute
          path="/yonet/geribildirimler"
          component={Geribildirimler}
        />
        <Login path="/giris" />
        <Login path="/" />
        <NotFound noLayout={true} default />
      </Router>
    </Layout>
  </ClientOnly>
)

export default Hesap
