import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { getDosyalar } from "utils"

function isFileShouldSend(fileType, row, dosyalar) {
  return !dosyalar.some(x =>
    x.dosya.endsWith(fileType == "fatura" ? "pdf" || "zip" : "lic")
  )
}

const OrderStatusConverter = ({ row }) => {
  const [dosyalar, setDosyalar] = React.useState([])

  useEffect(() => {
    getDosyalar(row.post_id)
      .then(response => response.json())
      .then(json => {
        setDosyalar(json)
      })
  }, [row])
  const spanGetir = (renk = "primary", yazi = "boş") => (
    <span
      style={{ fontSize: 13 }}
      className={`m-1 badge badge-pill badge-${renk}`}
    >
      {yazi}
    </span>
  )
  switch (row.post_status) {
    case "wc-cancelled":
      return spanGetir("secondary", "İptal Edildi")
    case "wc-pending":
      return spanGetir("primary", "Ödeme Bekleniyor")
    case "wc-on-hold":
      return spanGetir("primary", "Ödeme Bekleniyor")
    case "wc-processing":
      return (
        <div>
          {spanGetir("light", "İşleniyor")}
          {dosyalar &&
            isFileShouldSend("fatura", row, dosyalar) &&
            spanGetir("danger", "Fatura Gönderilmeli")}
          {dosyalar &&
            isFileShouldSend("lisans", row, dosyalar) &&
            spanGetir("warning", "Lisans Gönderilmeli")}
        </div>
      )
    case "wc-completed":
      return (
        <div>
          {spanGetir("success", "Tamamlandı")}
          {dosyalar &&
            isFileShouldSend("fatura", row, dosyalar) &&
            spanGetir("danger", "Fatura!")}
          {dosyalar &&
            isFileShouldSend("lisans", row, dosyalar) &&
            spanGetir("warning", "Lisans!")}
        </div>
      )
    case "trash":
      return spanGetir("danger", "Çöp")
    case "wc-refunded":
      return spanGetir("dark", "İade Edildi")
    default:
      return <span>{status}</span>
  }
}
OrderStatusConverter.propTypes = {
  row: PropTypes.object,
}

export default OrderStatusConverter
